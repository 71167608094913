
import { defineComponent, reactive, toRefs } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
  name: "ConfigUser",
  components: {},
  setup() {
    let data = reactive<any>({
      dialogFormVisible: false,
      dialogFormVisible2: false,

      roleForm: {
        selectedOptions: [],
        UID: "",
      },
      addForm: {
        account: "",
        source: "HealthCloud",
        role: "admin",
        memo: "",
      },
      form: {
        account: "",
      },
      userList: [],
      roleList: [],
      selectCity: {
        expandTrigger: "hover",
        lazy: true,
        checkStrictly: true,
        lazyLoad(node: any, resolve: any) {
          const { level, value } = node;
          console.log(node);

          switch (level) {
            case 0:
              Api.Api2.CityList(level).then((res: any) => {
                var items = [];
                if (res.code == "0") {
                  for (let i = 0; i < res.data.Items.length; i++) {
                    if (res.data.Items[i].Enabled) {
                      items.push({
                        value: res.data.Items[i].AreaId,
                        label: res.data.Items[i].AreaName,
                        leaf: false,
                      });
                    }
                  }
                }
                resolve(items);
              });
              break;
            case 1:
              Api.Api2.CityList(value).then((res: any) => {
                var items = [];
                if (res.code == "0") {
                  for (let i = 0; i < res.data.Items.length; i++) {
                    if (res.data.Items[i].Enabled) {
                      items.push({
                        value: res.data.Items[i].AreaId,
                        label: res.data.Items[i].AreaName,
                        leaf: false,
                      });
                    }
                  }
                }
                resolve(items);
              });
              break;
            case 2:
              Api.Api2.HospitalList(value).then((res: any) => {
                var items = [];
                if (res.code == "0") {
                  for (let i = 0; i < res.data.Items.length; i++) {
                    if (res.data.Items[i].Enabled) {
                      items.push({
                        value: res.data.Items[i].HospitalId,
                        label: res.data.Items[i].HospitalName,
                        leaf: true,
                      });
                    }
                  }
                }
                resolve(items);
              });
              break;
            default:
              resolve([]);
              break;
          }
        },
      },
    });

    const delRole = (row: any) => {
      Api.Api2.DelRoleRange(row).then((res: any) => {
        if (res.code == "0") {
          Api.Msg.success(res.data);
          data.dialogFormVisible2 = false;
        } else {
          Api.Msg.warning(res.data);
        }
      });
    };

    const addRole = () => {
      if (data.roleForm.selectedOptions.length == 0)
        Api.Msg.warning("请选择权限范围");
      var req = {
        UID: data.roleForm.UID,
        RangeType: data.roleForm.selectedOptions.length,
        RangeId:
          data.roleForm.selectedOptions[
            data.roleForm.selectedOptions.length - 1
          ],
      };
      Api.Api2.AddRoleRange(req).then((res: any) => {
        if (res.code == "0") {
          ElMessage.success(res.data);
          data.dialogFormVisible2 = false;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.data,
          });
          Api.Msg.warning(res.data);
        }
      });
    };

    const loadRole = (row: any) => {
      Api.Api2.RoleRangeList(row).then((res: any) => {
        if (res.code == "0") {
          data.roleList = res.data;
          data.roleForm.UID = row.UID;
          data.dialogFormVisible2 = true;
        }
      });
    };

    const getSource = (row: any) => {
      switch (row.source) {
        case "HealthCloud":
          return "健康云";
        default:
          return row.source;
      }
    };
    const getRole = (row: any) => {
      switch (row.role) {
        case "root":
          return "超级管理员";
        case "admin":
          return "管理员";
        case "third":
          return "第三方";
        default:
          return row.role;
      }
    };
    const getUser = () => {
      var acc = { account: data.form.account };
      Api.Api2.AdminList(acc).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.userList = res.data;
        }
      });
    };
    const addUser = () => {
      Api.Api2.AddAdmin(data.addForm).then((res: any) => {
        if (res.code == "0") {
          Api.Msg.success(res.data);
          getUser();
          data.addForm.account = "";
          data.addForm.memo = "";
          data.dialogFormVisible = false;
        } else {
          Api.Msg.warning(res.data);
        }
      });
    };
    const editUser = (row: any) => {
      Api.Api2.EditAdmin(row).then((res: any) => {
        if (res.code == "0") {
          Api.Msg.success(res.data);
          getUser();
        } else {
          Api.Msg.warning(res.data);
        }
      });
    };

    const resetPassword = (row: any) => {
      ElMessageBox.confirm("确定要重置该用户密码吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          Api.Api2.ResetAdminPWD(row).then((res: any) => {
            if (res.code == "0") {
              Api.Msg.success(res.data);
              getUser();
            } else {
              Api.Msg.warning(res.data);
            }
          });
        })
        .catch(() => {
          console.log("xx");
        });
    };

    return {
      ...toRefs(data),
      getUser,
      delRole,
      addRole,
      loadRole,
      getSource,
      getRole,
      addUser,
      editUser,
      resetPassword,
    };
  },
});
